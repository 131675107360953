import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormGeneratorDataListComponent} from './form-generator-data-list/form-generator-data-list.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    FormGeneratorDataListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "list",
            component: FormGeneratorDataListComponent
          }
        ]
      }
    ]),
    CtModelModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    MatIconModule,
  ]
})
export class FormGeneratorDataModule {
}
