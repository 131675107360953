import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtModelModule} from '@ctsolution/ct-framework';
import {LeadDynamicFormModule} from '../lead-dynamic-form/lead-dynamic-form.module';
import {LeadEditComponent} from './lead-edit.component';
import {LeadPlaceListModule} from "../lead-place-list/lead-place-list.module";
import {LeadEditFormModule} from './lead-edit-form/lead-edit-form.module';
import {LeadWorkOrderListModule} from '../lead-work-order-list/lead-work-order-list.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    LeadDynamicFormModule,
    LeadPlaceListModule,
    LeadEditFormModule,
    LeadWorkOrderListModule
  ],
  declarations: [LeadEditComponent]
})
export class LeadEditModule {
}
