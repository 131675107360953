import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableActionCustomConfiguration, CtModelConfiguration, CtSortOrderDescriptorParameter } from '@ctsolution/ct-framework';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { FormModuleDataRouteData } from '../../../_core/route-data/form-module-data.route-data';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { FileGet } from '../../../_core/classes/file-get';
import { FileService } from '../../../_core/lib/file.service';

@Component({
  selector: 'app-form-module-data-list',
  templateUrl: './form-module-data-list.component.html'
})
export class FormModuleDataListComponent implements AfterViewInit {

  configuration: CtModelConfiguration<FormModuleDataListComponent> | null = null;

  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;




  constructor(private general: CTMGeneralService, private fileService : FileService) { }


  ngAfterViewInit() {

    this.setup();

  }

  download(file: FileGet | null) {

    if (!file) return;

    this.fileService.downloadAttachmentByEndPoint(file);

  }

  setup() {

   const datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
  .setCustomDatatableActionConfiguration(
    CtDatatableActionCustomConfiguration
      .create()
      .setTemplateOutlet(this.downloadAttachmentButton));

    const configuration = CtModelConfiguration
      .create<FormModuleDataListComponent>()
      .setRouteData(FormModuleDataRouteData())
      .setOrderValues(
        new Array<CtSortOrderDescriptorParameter>(
          CtSortOrderDescriptorParameter.create('CreateAt').setIsDescending(true)))
      .setCTDatatableConfiguration(datatableConfiguration)
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["form-module-data"], "create", { qpHandling: 'preserve' }))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["form-module-data"], "edit", {
        value: event,
        configuration: this.configuration,
        qpHandling: 'preserve'
      }))

    this.configuration = configuration;

  }

}

