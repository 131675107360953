import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { WorkOrderDocumentsConfiguration } from './work-order-documents.configuration';
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtDatatableActionCustomConfiguration,
  CtDatatableConfiguration,
  CtGenericDialogComponent,
  CtGenericDialogConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, MAT_RAISED_PRIMARY, SnackbarService
} from '@ctsolution/ct-framework';
import { WorkOrderFileRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order-file.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from 'projects/ct-manager/src/app/_core/constants';
import { FileService } from 'projects/ct-manager/src/app/_core/lib/file.service';
import { FileGet } from 'projects/ct-manager/src/app/_core/classes/file-get';
import { WorkOrderDocumentsService } from './work-order-documents.service';
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';

@Component({
  selector: 'ctm-work-order-documents',
  templateUrl: './work-order-documents.component.html',
  styleUrls: ['./work-order-documents.component.scss']
})
export class WorkOrderDocumentsComponent {

  @Input() configuration: WorkOrderDocumentsConfiguration | null = null;

  modelConfiguration: CtModelConfiguration<WorkOrderDocumentsComponent> | null = null
  datatableConfiguration: CtDatatableConfiguration | null = null;
  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;
  @ViewChild("legendTemplate") legendTemplate: TemplateRef<any> | null = null;

  showZipButton = false;
  zipDownloadAttachmentButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setAction(() => this.downloadZip())
    .setLabel("Download zip")
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setIcon("download");

  constructor(private generalService: CtModelService<any>, private fileService: FileService, private dialog: MatLegacyDialog, private workOrderDocumentsService: WorkOrderDocumentsService, private snackbar: SnackbarService) {
  }

  ngAfterViewInit(): void {

    this.setup()

  }

  setup() {

    this.datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
      .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadAttachmentButton));

    const configuration = CtModelConfiguration
      .create<WorkOrderDocumentsComponent>()
      .setRouteData(WorkOrderFileRouteData())
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setOnCreate(() => {

        this.configuration?.setDocument(null)
        const dialogRef = this.workOrderDocumentsService.open(this.configuration)

        dialogRef
          .afterClosed()
          .subscribe(value => {

            if (value) {

              this.snackbar.onSuccess();
              this.reloadCurrentPath();

            }

          })

      })
      .setOnEdit((event: any) => {

        this.configuration?.setDocument(event);
        if (!event.Oid) {

          this
            .dialog
            .open(CtGenericDialogComponent, {
              data: {
                configuration:
                  CtGenericDialogConfiguration.create()
                    .setContent("warningFileDocument")
                    .setTitle("warning")
                    .setCancelLabel("close"),
              }, maxWidth: '500px', minWidth: '300px'
            })
        }
        else {
          const dialogRef = this.workOrderDocumentsService.open(this.configuration)

          dialogRef
            .afterClosed()
            .subscribe(value => {

              if (value) {

                this.snackbar.onSuccess();
                this.reloadCurrentPath();

              }

            })

        }
      })


    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('WorkOrder.Oid')
        .setValue(this.configuration?.WorkOrder.Oid)
        .setOperatorType(CtBinaryOperator.Equal))


    this.modelConfiguration = configuration;

    this.modelConfiguration?.setLegendTemplate(this.legendTemplate)

    this.checkListDocuments()

  }

  checkListDocuments() {

    if (!this.modelConfiguration) return;

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('WorkOrder.Oid')
          .setValue(this.configuration?.WorkOrder.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);



    this.generalService.getList(this.modelConfiguration, filters).subscribe((response: CtWebapiGenericResponse<any>) => {

      if (response.Result.DataSource) {

        const res: Array<any> = response.Result.DataSource;

        if (res.length > 1) {

          this.showZipButton = true;

        }

      }

    })


  }

  private reloadCurrentPath(): void {

    let currentPath = window.location.pathname;

    const hasQuestionMark = currentPath.includes('?');

    if (!hasQuestionMark) currentPath += '?rows=true';

    window.location.href = window.location.origin + currentPath;

  }

  download(file: FileGet | null) {

    if (!file) return;

    this.fileService.downloadAttachmentByEndPoint(file);

  }

  downloadZip() {

    const endpoint = `/WorkOrder/GetDocumentsZip/${this.configuration?.WorkOrder.Oid}`;

    const value : FileGet ={
      Endpoint: endpoint
    };
    this.fileService.downloadAttachmentByEndPoint(value)

  }


}
