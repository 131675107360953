import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadListComponent } from './lead-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { StateLeadBulletModule } from './state-lead-bullet/state-lead-bullet.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    StateLeadBulletModule
  ],
  declarations: [LeadListComponent],
  exports : [LeadListComponent]
})
export class LeadListModule { }
