import {Routes} from "@angular/router";
import {CtModelComponent, CtModelType} from "@ctsolution/ct-framework";
import {WorkOrderModule} from "../components/work-order/work-order.module";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {HandlingModule} from "../components/handling/handling.module";
import {WarehouseModule} from "../components/warehouse/warehouse.module";
import {CatalogModule} from "../components/catalog/catalog.module";
import {ItemImportModule} from "../components/item-import/item-import.module";
import {UserWorkingHoursModule} from "../components/user-working-hours/user-working-hours.module";
import {UserModule} from "../components/user/user.module";
import {CompanyModule} from "../components/company/company.module";
import {ContactModule} from "../components/contact/contact.module";
import {LeadModule} from "../components/lead/lead.module";
import {FormGeneratorDataModule} from "../components/form-generator-data/form-generator-data.module";
import { OfferModule } from "../components/offer/offer.module";
import { FormModuleDataModule } from "../components/form-module-data/form-module-data.module";

export const PagesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "La tua dashboard",
          urls: [{title: "Dashboard"}]
        }
      },
      {
        path: "handling",
        loadChildren: () => HandlingModule
      },
      {
        path: "location",
        loadChildren: () => WarehouseModule
      },
      {
        path: 'user-working-hours',
        loadChildren: () => UserWorkingHoursModule
      },
      {
        path: "work-order",
        loadChildren: () => WorkOrderModule
      },
      {
        path: "catalog",
        loadChildren: () => CatalogModule
      },
      {
        path: "user",
        loadChildren: () => UserModule
      },
      {
        path: "customer",
        loadChildren: () => CompanyModule
      },
      {
        path: "contact",
        loadChildren: () => ContactModule
      },
      {
        path: "lead",
        loadChildren: () => LeadModule
      },
      {
        path: "form-generator-data",
        loadChildren: () => FormGeneratorDataModule
      },
      {
        path: "form-module-data",
        loadChildren: () => FormModuleDataModule
      },
      {
        path: "offer",
        loadChildren: () => OfferModule
      },
      {
        path: "ct-model",
        children: [
          {
            path: "item-import",
            loadChildren: () => ItemImportModule
          },
          {
            path: ":controller",
            children: [
              {
                path: "",
                redirectTo: "list",
                pathMatch: "full"
              },
              {
                path: "create",
                component: CtModelComponent,
                data: {type: CtModelType.FORM}
              },
              {
                path: "edit/:id",
                component: CtModelComponent,
                data: {type: CtModelType.FORM}
              },
              {
                path: "list",
                component: CtModelComponent
              },
              {
                path: "**",
                redirectTo: "list",
                pathMatch: "full"
              }
            ]
          }
        ]
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      }
    ]
  }
];
