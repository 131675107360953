import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {WorkOrderRouteData} from "../../../_core/route-data/work-order.route-data";
import {Router} from "@angular/router";

@Component({
  selector: 'app-company-contract',
  template: `
    <div *ngIf="configuration">

      <h5> {{ 'workorder' | translate }} </h5>

      <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    </div>
  `
})
export class CompanyContractComponent {

  @Input() masterConfiguration: CtModelConfiguration<any> | null = null;
  configuration: CtModelConfiguration<any> | null = null;

  constructor(private router: Router) {
  }

  ngOnInit() {

    if (this.masterConfiguration?.RouteData?.id) {

      this.setup();

    }

  }

  setup() {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(WorkOrderRouteData())
      .setDisableAutoQueryFilter(true)
      .setOnEdit(event => this.router
        .navigate(['/', 'work-order', 'edit', event.Oid]))
      .setFieldsCustomTemplates([
        {prop: 'Company', visible: false},
        {prop: 'CompanyFiscalCode', visible: false},
        {prop: 'CompanyVAT', visible: false},
        {prop: 'Quotation', visible: false}
      ])

    configuration
      .initialized()
      .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update]))

    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Company.Oid')
        .setValue(this.masterConfiguration?.RouteData?.id)
        .setOperatorType(CtBinaryOperator.Equal))

    this.configuration = configuration;

  }

}
