import {Component, ViewChild} from '@angular/core';
import {
  CtModelRouteData,
  CtModelType,
  CtModelConfiguration,
  CtModelService,
  CtDatatableContainerFilterValues, SnackbarService
} from '@ctsolution/ct-framework';
import {LeadRouteData} from '../../../_core/route-data/lead.route-data';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {CtWebapiGenericResponse} from '@ctsolution/ct-webapi';
import {LeadEditFormComponent} from './lead-edit-form/lead-edit-form.component';

@Component({
  selector: 'app-lead-edit',
  templateUrl: './lead-edit.component.html',

})
export class LeadEditComponent {

  @ViewChild(LeadEditFormComponent) leadEditFormComponent!: LeadEditFormComponent;

  leadModelRouteData: CtModelRouteData = LeadRouteData().setModelType(CtModelType.FORM);
  leadModelConfiguration: CtModelConfiguration<any> = CtModelConfiguration.create();
  datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null
  leadOid : number | null = null;


  get enableMasterDetail(): boolean {

    return !!this.leadModelConfiguration?.RouteData?.id;

  }

  showPlace = true;

  onValueChanged(shouldShow: boolean) {
    this.showPlace = shouldShow;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _snackbar: SnackbarService,
    private modelService: CtModelService<any>) {

    this.leadModelConfiguration.setRouteData(this.leadModelRouteData);

    this.setupConfiguration();

  }

  async setupConfiguration() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) {

      this.leadOid = +pId;

      this.leadModelRouteData.setId(+pId)

      this.modelService
        .getInfos(this.leadModelConfiguration)
        .subscribe({
          next: async (response) => {

            const dataSource = response?.Result?.DataSource;

            if (dataSource) {

              this.leadEditFormComponent.setup(dataSource);

            }

          },
          error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
        })

    }

    this.route.data.subscribe(data => data["title"] = "Lead");
    this.route.queryParams.subscribe(params => {
      const q = params['q'];
      if (q) {

        const filterValues = JSON.parse(q);
        this.datatableContainerFilterValues = CtDatatableContainerFilterValues.create(filterValues);

      }
    });

  }

  submit(formGroup: FormGroup | null) {

    if (!formGroup) return;

    formGroup.markAllAsTouched();


    if (formGroup.valid) {

      const value: ILead = formGroup.value as ILead;

      if (value.LeadType.Code) {

        value.LeadType = value.LeadType.Code;

      }

      const Tags: { Oid: number }[] = (value?.Tags ?? []).map(tag => ({Oid: tag.value}));

      const formValue: any = {
        Customer: value.Customer ? {Oid: value.Customer.value} : null,
        CustomerType: value.CustomerType ? {Oid: value.CustomerType} : null,
        DocumentDate: value.DocumentDate,
        Email: value.Customer ? null : value.Email,
        LeadInterest: value.LeadInterest ? {Oid: value.LeadInterest} : null,
        LeadSource: value.LeadSource ? {Oid: value.LeadSource} : null,
        LeadStatus: value.LeadStatus ? {Oid: value.LeadStatus} : null,
        LeadType: value.LeadType ? {Oid: value.LeadType} : null,
        Name: value.Customer ? null : value.Name,
        Phone: value.Customer ? null : value.Phone,
        Surname: value.Customer ? null : value.Surname,
        CompanyPlace: value.CompanyPlace ? {Oid: value.CompanyPlace} : null,
        Code: value.Code,
        Note: value.Note,
        VatNumber: (<any>value).VATNUMBER ?? null,
        SdiCode: (<any>value).SDICODE ?? null,
        Tags

      }; //TODO guardare dts per

      this.modelService
        .putInfos(this.leadModelConfiguration, formValue)
        ?.subscribe({
          next: (response: CtWebapiGenericResponse<number | null>) => {

            this._snackbar.onSuccess();

            const commands = ['/', 'lead'];

            if (response.Result && !isNaN(response?.Result)) {

              commands
                .push(
                  'edit',
                  response.Result?.toString());

              this.router.navigate(commands, {queryParamsHandling: 'preserve'});

            } else {

              this.setupConfiguration();

            }

          },
          error: (err) => console.error('Errore durante l\'operazione:', err)
        });
    }

  }

}

export interface ILead {

  Customer: any | null
  CustomerType: number | null
  DocumentDate: any
  Email: string
  LeadInterest: number | null
  LeadSource: number | null
  LeadStatus: number
  LeadType: any
  Name: string
  Phone: string
  Surname: string
  CompanyPlace: number | null
  Code: string | null
  Tags: any[] | null
  Note : string | null

}
