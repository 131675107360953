<ng-container *ngIf="pId; else errorTemplate">

  <mat-tab-group>

    <mat-tab [label]="'CT_MENU.locationstock' | translate">

      <app-warehouse-stocks [referenceId]="pId"></app-warehouse-stocks>

    </mat-tab>

    <mat-tab [label]="'CT_MENU.handling' | translate">

      <app-warehouse-handlings [referenceId]="pId"></app-warehouse-handlings>

    </mat-tab>

  </mat-tab-group>

</ng-container>

<ng-template #headerActions>

  <button (click)="getPackagingLabelPDF()" mat-mini-fab color="primary" *ngIf="boxLocation">
    <mat-icon>print</mat-icon>
  </button>

  <button (click)="reportQR()" mat-mini-fab color="primary">
    <mat-icon>qr_code_2</mat-icon>
  </button>

  <app-lang-download-file [configuration]="langDownloadFileConfiguration"
    (onDownload)="reportPDF($event)"></app-lang-download-file>

</ng-template>
<ng-template #errorTemplate>

  <p> An error occurred. If this issue persists please contact us through our help center at info@ctsolution.it</p>

</ng-template>
