import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input, AfterViewInit
} from "@angular/core";
import {MediaMatcher} from "@angular/cdk/layout";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";
import {ChildrenItems, Menu} from "../menu/_classes/menu-item.interface";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: "app-vertical-sidebar",
  templateUrl: "./vertical-sidebar.component.html",
  styles: [`
    .vertical-sidebar {

      .mat-icon {

        min-width: 22px !important;

      }

    }
  `]
})
export class VerticalAppSidebarComponent implements OnDestroy, AfterViewInit {

  mobileQuery: MediaQueryList;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _mobileQueryListener: () => void;
  status = true;
  itemSelect: number[] = [];

  // @ts-ignore
  menuItems$: BehaviorSubject<Menu[]> = new BehaviorSubject([]);

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private menuItems: CtManagerService,
    private router: Router
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const items = await this.menuItems.getMenuItems();

    this.menuItems$.next(items);

  }

  handleNotify(menuitem: Menu, childitem?: ChildrenItems) {

    if (menuitem.state === "dashboard") {

      this.router.navigateByUrl("/");
      return;

    }

    let states: string[] = ["/"];

    if (childitem) {

      const currMenuItem = menuitem;

      menuitem = childitem as Menu;
      menuitem.separator = [{name: currMenuItem.state, type: 'link'} as Menu]

    }

    if (menuitem.separator?.length) {

      states = states.concat(menuitem.separator.map((elm: any) => elm.name));

    }

    states.push(menuitem.state);

    // Serve a ricaricare il component CtModel simulando la navigazione da un'altra parte
    this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => {

        const extras: any = {};

        if (menuitem.query) extras.queryParams = {q: JSON.stringify(menuitem.query)};

        this.router
          .navigate(states, extras)
          .then(() => {

            if (window.innerWidth < 1024) {

              this.notify.emit(!this.showClass);

            }

          })

      });

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
