import { Injectable } from '@angular/core';
import { CustomerType } from "../../_core/enum/customer-type";
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CtGenericDialogComponent, CtGenericDialogConfiguration } from '@ctsolution/ct-framework';

@Injectable()
export class CompanyService {


  constructor(private dialog: MatLegacyDialog, private route: ActivatedRoute, private router: Router) { }
  getCustomerTypeByRouteValue(typePathValue: string | null): CustomerType | null {

    switch (typePathValue) {

      case "supplier":
        return CustomerType.Supplier;
      case "private":
      default:
        return CustomerType.Private;

    }

  }

  navigateToLead(leadOid: string) {

    this
      .dialog
      .open(CtGenericDialogComponent, {
        data: {
          configuration:
            CtGenericDialogConfiguration.create()
              .setContent("leadNavigation")
              .setTitle("alert")
              .setCancelLabel("No")
              .setSubmitLabel("backToLead"),
        }, maxWidth: '500px', minWidth: '300px'
      })
      .afterClosed()
      .subscribe(res => {

        this.dialog.closeAll();
        if (res) this.router
          .navigate(['/', 'lead', 'edit', leadOid])

      });

  }
}
