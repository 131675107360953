export class MovementManagerConfiguration {

  IsQuotation: boolean | null = null;
  WorkOrder: { Oid: number } | null = null;

  private constructor() {
  }

  public static create = () => new MovementManagerConfiguration();

  setWorkOrderOid(value: number | null) {

    this.WorkOrder = value ? {Oid: value} : null;
    return this;

  }

  setIsQuotation(value: boolean | null) {

    this.IsQuotation = value;
    return this;

  }

}
