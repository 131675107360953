import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WorkOrderEditComponent} from "./work-order-edit.component";
import {WorkOrderDefaultEditComponent} from './work-order-default-edit/work-order-default-edit.component';
import {MatTabsModule} from "@angular/material/tabs";
import {GeneralDataModule} from "./work-order-default-edit/general-data/general-data.module";
import {MovementManagerModule} from "./work-order-default-edit/movement-manager/movement-manager.module";
import {CtButtonModule} from "@ctsolution/ct-framework";
import {FlexModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {WorkOrderHandlingsModule} from "./work-order-default-edit/work-order-handlings/work-order-handlings.module";
import { WorkOrderDocumentsModule } from "./work-order-default-edit/work-order-documents/work-order-documents.module";
import { WorkOrderSummaryModule } from "./work-order-default-edit/work-order-summary/work-order-summary.module";

@NgModule({
  declarations: [
    WorkOrderEditComponent,
    WorkOrderDefaultEditComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    GeneralDataModule,
    MovementManagerModule,
    CtButtonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    WorkOrderHandlingsModule,
    WorkOrderDocumentsModule,
    WorkOrderSummaryModule
  ],
  exports: [
    WorkOrderEditComponent
  ]
})
export class WorkOrderEditModule {
}
