<mat-card>

  <mat-card-content [formGroup]="form" style="padding-top: 0px;">

    <mat-radio-group class="movement-manager-insert-mode-container" [fxLayout]="'row wrap'"
                     formControlName="insertMode">

      <div [fxFlex]="fxFlexValue" fxFlex.sm="100" class="p-10"
           *ngFor="let mode of getEnumKeys()">

        <button
          mat-raised-button
          (click)="control.setValue(insertModes[mode])">
          <button mat-mini-fab color="primary">
            <mat-icon>{{ insertModeDetailsMap[insertModes[mode]]?.icon ?? '' }}</mat-icon>
          </button>
          {{ insertModeDetailsMap[insertModes[mode]]?.description ?? '' | translate }}
        </button>

      </div>

    </mat-radio-group>

  </mat-card-content>

</mat-card>
