import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  CtModelConfiguration,
  CtModelRouteData, CtModelService,
  CtModelType,
} from "@ctsolution/ct-framework";
import {WorkOrderRouteData} from "../../../../_core/route-data/work-order.route-data";
import {CTWorkOrder} from "../../../../_core/classes/ct-work-order";
import {GeneralDataComponent} from "./general-data/general-data.component";
import {CTMWorkOrderViewType} from "../../../../_core/enum/work-order-type";
import {WorkOrderService} from "../../work-order.service";
import {GeneralDataConfiguration} from "./general-data/general-data.configuration";
import {MovementManagerComponent} from "./movement-manager/movement-manager.component";
import {CtManagerService} from "../../../../_core/lib/ct-manager.service";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CTWorkOrderRow} from "../../../../_core/classes/ct-work-order.row";
import {CtWorkOrderInformationContent} from "../../../../_core/classes/ct-work-order.information-content";
import {CTWorkOrderRowUnionType} from "../../../../_core/classes/ct-work-order.row-union";
import {MovementManagerConfiguration} from "./movement-manager/movement-manager.configuration";
import {WorkOrderHandlingsConfiguration} from "./work-order-handlings/work-order-handlings.configuration";
import {WorkOrderDocumentsConfiguration} from './work-order-documents/work-order-documents.configuration';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-work-order-default-edit',
  templateUrl: `./work-order-default-edit.component.html`,
  styleUrls: [`./work-order-default-edit.component.scss`]
})
export class WorkOrderDefaultEditComponent {

  pId: string | null = null;
  configuration: CtModelConfiguration<WorkOrderDefaultEditComponent> | null = null;

  viewModel: WorkOrderDefaultEditViewModel = {
    workOrder: null,
    workOrderViewType: CTMWorkOrderViewType.Standard,
    workOrderRowHandlingEnabled: false,
    workOrderExtraDataEnabled: false,
    selectedIndex: 0,
    masterDetail: false

  }

  generalDataConfiguration: GeneralDataConfiguration | null = null;
  movementManagerConfiguration: MovementManagerConfiguration | null = null;
  workOrderHandlingsConfiguration: WorkOrderHandlingsConfiguration | null = null;
  workOrderDocumentsConfiguration: WorkOrderDocumentsConfiguration | null = null;

  @ViewChild('generalDataComponent') generalDataComponent: GeneralDataComponent | null = null;
  @ViewChild('movementManagerComponent') movementManagerComponent: MovementManagerComponent | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private workOrderService: WorkOrderService,
    private managerService: CtManagerService,
    private CTModelService: CtModelService<any>) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngOnInit() {

    this.setup();

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp['rows']) {

          setTimeout(() => this.viewModel.selectedIndex = 1, 200)

        }

      })

  }

  get isQuotation() {

    return this.viewModel.workOrderViewType === CTMWorkOrderViewType.Quotation;

  }

  async setup() {

    this.viewModel.workOrderViewType = await this.workOrderService.getRouteWorkOrderType(this.route);
    this.viewModel.workOrderExtraDataEnabled = await this.managerService.hasEnabled.monitoring();

    const routeData: CtModelRouteData = WorkOrderRouteData()
      .setModelType(CtModelType.FORM);

    if (this.pId) {

      routeData.setId(+this.pId);
      this.viewModel.workOrderRowHandlingEnabled = await this.managerService.hasEnabled.warehouse();
      this.viewModel.masterDetail = true;

    }

    this.configuration = CtModelConfiguration
      .create<WorkOrderDefaultEditComponent>()
      .setRouteData(routeData);

    if (this.pId) {

      this.CTModelService
        .getInfos(this.configuration)
        .subscribe({
          next: async (response) => {

            if (response && response?.Result?.DataSource) {

              this.viewModel.workOrder = CTWorkOrder.create<CtWorkOrderInformationContent>(response?.Result?.DataSource);

              if (this.viewModel.workOrder.Oid) {

                const [rows, rowsItem, rowsOffer, rowsContact] = await Promise.all([
                  this.workOrderService.getRows(this.viewModel.workOrder.Oid).catch(error => []),
                  [], //this.workOrderService.getRowsItem(this.viewModel.workOrder.Oid).catch(error => []),
                  [], //this.workOrderService.getRowsOffer(this.viewModel.workOrder.Oid).catch(error => []),
                  [] //this.workOrderService.getRowsContact(this.viewModel.workOrder.Oid).catch(error => [])
                ]);

                this.route
                  .data.subscribe(data => {

                  const {title, code, referenceName} = {
                    title: this.translate.instant(this.isQuotation ? "QUOTATION" : "WorkOrder"),
                    code: this.viewModel.workOrder?.Code,
                    referenceName: response?.Result?.DataSource.Company.ReferenceName
                  };

                  data['title'] = [title, code, referenceName].filter(Boolean).join(' - ');

                  this.cdr.detectChanges();

                  this.cdr.detectChanges();

                })

                this.viewModel.workOrder.Rows = new Array<CTWorkOrderRowUnionType>();

                [rows, rowsItem, rowsOffer, rowsContact]
                  .forEach(rowArray => {

                    if (rowArray?.length) rowArray.forEach(r => this.viewModel.workOrder?.Rows.push(CTWorkOrderRow.create(r)));

                  });

                if (this.generalDataComponent) this.generalDataComponent.setup(this.viewModel.workOrder);

                if (this.movementManagerComponent) this.movementManagerComponent.setup(this.viewModel.workOrder);

              }

            }

          },
          error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
        })

    }

    this.generalDataConfiguration = GeneralDataConfiguration
      .create(this.viewModel.workOrderViewType)
      .setWorkOrderOid(this.pId ? +this.pId : null)
      .enableImport(this.viewModel.workOrderRowHandlingEnabled)
      .enableExtraDataJson(this.viewModel.workOrderExtraDataEnabled);

    this.movementManagerConfiguration = MovementManagerConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)
      .setIsQuotation(this.isQuotation);

    this.workOrderHandlingsConfiguration = WorkOrderHandlingsConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)

    this.workOrderDocumentsConfiguration = WorkOrderDocumentsConfiguration
      .create()
      .setWorkOrderOid(this.pId ? +this.pId : null)

    this.route
      .data
      .subscribe(data => {

        data['title'] = (this.isQuotation) ? "CT_MENU.quotations" : "CT_MENU.workorder";
        data['urls'] = [{title: (this.isQuotation) ? 'quotation-form' : 'workorder-form'}];

      })

    this.cdr.detectChanges();

  }

  submit(acceptQuotationDate: Date | null) {

    if (!this.configuration || this.generalDataComponent?.formConfiguration?.form?.invalid) return;

    const generalDataValue = this.generalDataComponent?.formConfiguration?.form?.value;

    if (!generalDataValue) {

      console.error('Dati non validi');
      return;

    }

    const workOrder = CTWorkOrder
      .create<CtWorkOrderInformationContent>()
      .setName(generalDataValue.Name)
      .setCompanyOid(generalDataValue.Customer.value)
      .setCode(generalDataValue.Code)
      .setStateOid(generalDataValue.WorkOrderState)
      .setStartAt(generalDataValue.SupplyStartDate)
      .setExpiredAt(generalDataValue.SupplyExpirationDate)
      .setAddress(generalDataValue.Address?.value ? generalDataValue.Address.label : generalDataValue.Address)
      .setDescription(generalDataValue.Description)
      .setExtraDataJSON(generalDataValue.ExtraDataJSON ?? null);

    if (this.isQuotation) {

      if (acceptQuotationDate) {

        workOrder
          .setQuotationAcceptAt(acceptQuotationDate);

      }

      workOrder
        .setQuotation(true);

    }

    this.CTModelService
      .putInfos(this.configuration, workOrder)
      ?.subscribe({
        next: (response: CtWebapiGenericResponse<number | null>) => {

          const commands = ['/', 'work-order'];

          if (response.Result && !isNaN(response?.Result)) {

            if (this.isQuotation) commands.push('quotations')

            commands
              .push('edit', response.Result?.toString());

            this.router
              .navigate(commands);

          } else if (this.pId && this.isQuotation && !!acceptQuotationDate) {

            commands
              .push('edit', this.pId);

            this.router
              .navigate(commands);

          } else {

            this.setup();

          }

        },
        error: (err) => console.error('Errore durante l\'operazione:', err)
      });
  }

}

export interface WorkOrderDefaultEditViewModel {
  workOrder: CTWorkOrder<CtWorkOrderInformationContent> | null;
  workOrderViewType: CTMWorkOrderViewType | null;
  workOrderRowHandlingEnabled: boolean;
  workOrderExtraDataEnabled: boolean;
  selectedIndex: number;
  masterDetail: boolean;
}
