import {AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import {CtModelConfiguration} from '@ctsolution/ct-framework';
import {CTMGeneralService} from '../../../_core/lib/general.service';
import {LeadRouteData} from '../../../_core/route-data/lead.route-data';
import {DEFAULT_DATATABLE_CONFIGURATION} from '../../../_core/constants';
import {StateLeadBulletConfiguration} from './state-lead-bullet/state-lead-bullet.configuration';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styles: ['::ng-deep .datatable-body-cell-label {height : 100%}']
})
export class LeadListComponent implements AfterViewInit {

  configuration: CtModelConfiguration<LeadListComponent> | null = null;
  @ViewChild('StateCellTemplate') stateCellTemplate: TemplateRef<any> | null = null;

  constructor(private general: CTMGeneralService, private route : ActivatedRoute, private cdr: ChangeDetectorRef ) {
  }

  ngAfterViewInit() {

    this.route
    .queryParams
    .subscribe(qp => {

      const name = qp['Name'] ?? null;

      this.route
        .data
        .subscribe(data => {

          data['title'] = 'CT_PAGE.BREADCRUMBS.DetailLead';
          this.cdr.detectChanges();

        })

    });

    this.setup();

  }

  async setup() {

    this.configuration = CtModelConfiguration
      .create<LeadListComponent>()
      .setRouteData(LeadRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["lead"], "create", {qpHandling: 'preserve'}))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["lead"], "edit", {
        value: event,
        configuration: this.configuration,
        qpHandling: 'preserve'
      }))
      .setFieldsCustomTemplates([
        {
          prop: 'LeadStatus',
          cellTemplate: this.stateCellTemplate,
          filterOptions: {
            multiple: true
          } as any
        },
      ]);

  }


  stateBulletConfiguration = (state: string) => StateLeadBulletConfiguration
    .create(state)

}
