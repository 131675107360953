import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  CtButtonConfiguration,
  CtControlTypes, CtFileUploaderConfiguration,
  CTGeneralService,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {CTMGeneralService} from "../../../../../../../_core/lib/general.service";
import {WorkOrderFileCreateParameter} from "../../../../../../../_core/classes/work-order-file-create.parameter";

@Component({
  selector: 'app-work-order-documents-form',
  templateUrl: './work-order-documents-form.component.html',
  styleUrls: ['./work-order-documents-form.component.scss']
})
export class WorkOrderDocumentsFormComponent {

  form: FormGroup;
  @Input() configuration: WorkOrderFileCreateParameter | null = null;
  @Output() onSubmit: EventEmitter<WorkOrderFileCreateParameter> = new EventEmitter<WorkOrderFileCreateParameter>();
  @Output() onCancel: EventEmitter<WorkOrderFileCreateParameter> = new EventEmitter<WorkOrderFileCreateParameter>();


  formValue : any | null = null;

  CTFileUploaderConfiguration: CtFileUploaderConfiguration = CtFileUploaderConfiguration
    .create()
    .setTitle(null)
    .setLimit(1)
    .setAcceptedFormats(['application/pdf', 'image/jpeg', 'image/png', 'text/html',".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"])
    .setControlValidators([Validators.required])

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel.emit())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  noteControl = this.CTMGeneralService.getTextControl({
    name: 'Note',
    required: false,
  })
    .setType(CtControlTypes.TEXTAREA)
    .setFlexSpacePercentage(100)
    .setTheme(CtThemeTypes.MATERIAL);

  typeControl = this.CTMGeneralService.getLookupControlDefaultConfiguration({
    name: 'WorkOrderFileType',
    controller: 'WorkOrderFileType',
    type: CtControlTypes.ENUMERABLE,
    required: true
  })
    .setLabel('WorkOrderFileType.control')
    .setFlexSpacePercentage(100)
    .setTheme(CtThemeTypes.MATERIAL);

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private CTMGeneralService: CTMGeneralService,
    private CTGeneralService: CTGeneralService) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.setup();

    this.cdr.detectChanges();

  }

  setup() {

    this.typeControl
      .setFormParent(this.form);

    this.noteControl
      .setFormParent(this.form);

    this.form
      .addControl('File', this.CTFileUploaderConfiguration.control)

      if(this.configuration) {

        this.noteControl.setValue(this.configuration.Note);
        this.formValue = this.configuration;

      }


  }

  submit() {

      if(this.formValue){

        const value = this.form.value;

        const parameter = WorkOrderFileCreateParameter
        .create()
        .setType(this.formValue.Type.Oid)
        .setNote(value.Note)
        .setOid(this.formValue.Oid)


        this.onSubmit.emit(parameter)
        return
      }

    this.form.markAllAsTouched();

    if (this.form.invalid) return;

    const value = this.form.value;

    const parameter = WorkOrderFileCreateParameter
      .create()
      .setFile(value.File)
      .setType(value.WorkOrderFileType)

    if (value.Note) {

      parameter
        .setNote(value.Note)

    }

    this.onSubmit.emit(parameter)

  }

}
