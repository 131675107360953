import { Injectable } from "@angular/core";
import {
  CTModelDatatableFilter,
  CtControlService,
  CtControlTypes,
  CtModelConfiguration,
  CtModelDatatableOperators,
  CtModelRouteData,
  CtModelService,
  CtSelectControlOptions,
  CtSelectControlRemoteDefaults,
  CtSortOrderDescriptorParameter,
  CTDatatablePaginationParameter
} from "@ctsolution/ct-framework";
import { Router } from "@angular/router";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";

@Injectable({
  providedIn: "root"
})
export class CTMGeneralService {

  constructor(private router: Router, private controlService: CtControlService, private CTModelService: CtModelService<any>) {
  }

  navigateOnCTModelRouter(controller: string[], action: "create" | "detail" | "edit", dto?: {
    value?: any,
    configuration?: CtModelConfiguration<any> | null,
    qpKeys?: string[],
    qpHandling?: 'preserve' | 'merge' | null
  }) {

    const commands: string[] = ["/", ...controller, action];
    const queryParams: any = {};

    if (dto?.value) {

      if ((dto.qpKeys ?? []).length) {

        dto.qpKeys
          ?.forEach(k => queryParams[k] = dto.value[k]);

      }

      const CTFieldInfosDTOKey: string | null = dto.configuration?.getCTFieldInfosDTOKey() ?? null;

      if (CTFieldInfosDTOKey && dto.value) {

        commands.push(dto.value[CTFieldInfosDTOKey]);

      }

    }

    const extras: any = { queryParams }

    if (dto?.qpHandling) {

      extras.queryParamsHandling = dto.qpHandling;

    }

    this.router.navigate(commands, extras);

  }

  getTextControl(value: { name: string, required?: boolean | null; flexSpacePercentage?: number | null }) {

    return this.controlService
      .getDefaultConfiguration<string | null>({
        name: value.name,
        flexSpacePercentage: value.flexSpacePercentage ?? 50,
        type: CtControlTypes.TEXT,
        required: value.required ?? false
      })

  }

  getInfos(value: { controller: string, oid?: number }) {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(CtModelRouteData.create().setController(value.controller))

    if (value.oid) {

      configuration.RouteData?.setId(value.oid);

    }

    return this.CTModelService.getInfos(configuration);

  }

  getList(value: {
    controller: string,
    filters?: CTModelDatatableFilter[],
    pagination?: CTDatatablePaginationParameter | null
  }) {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(CtModelRouteData.create().setController(value.controller));

    const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters(value.filters ?? [])

    if (value.pagination) {

      body
        .setPagination(value.pagination);

    }

    return this.CTModelService.getList(configuration, body);

  }

   getLookupControlDefaultConfiguration(value: {
    name: string;
    controller: string;
    key?: string | null;
    type?: CtControlTypes | null;
    required?: boolean | null;
    action?: string | null;
  }) {

    const { key, type, required, action } = {
      key: value.key ?? 'Name',
      type: value.type ?? CtControlTypes.LOOKUP,
      required: value.required ?? true,
      action: value.action ?? 'Lookup',
    };

    const configuration = this.controlService
      .getDefaultConfiguration<string | null>({
        name: value.name,
        type,
        flexSpacePercentage: 50,
        required: required,
        remoteValueOptionsEndpoint: DataRequest
          .create()
          .setController([value.controller])
          .setAction(action)
          .setMethod(MethodEnum.POST)
          .setQueryParams({ hideSpinner: true })
      })
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          ?.setNullValueEnabled(false)
          ?.setRemoteDefaults(CtSelectControlRemoteDefaults.create().setSearchKey(key).setSortOrder(CtSortOrderDescriptorParameter.create(key)))

      });

    return configuration;

  }

  getDateControlDefault(value: { name: string; flexSpacePercentage?: number | null; required?: boolean | null; }) {

    const configuration = this.controlService.getDefaultConfiguration<Date | null>({
      name: value.name,
      type: CtControlTypes.DATE,
      required: value.required ?? true,
      flexSpacePercentage: value.flexSpacePercentage ?? 33.33
    })

    return configuration;

  }

  putData(config: CtModelConfiguration<any>, value: any) {

    if (value.Oid) config.RouteData?.setId(value.Oid);

    return this.CTModelService.putInfos(config, value)?.toPromise();

  }

}
