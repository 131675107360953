import {Component, Inject, ViewChild} from '@angular/core';
import {WorkOrderDocumentsFormComponent} from "./work-order-documents-form/work-order-documents-form.component";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {WorkOrderDocumentsConfiguration} from "../work-order-documents.configuration";
import {WorkOrderFileCreateParameter} from "../../../../../../_core/classes/work-order-file-create.parameter";
import {CtModelConfiguration, CtModelService} from "@ctsolution/ct-framework";
import {CtSerializerHelperService} from "@ctsolution/ct-webapi";
import {WorkOrderFileRouteData} from "../../../../../../_core/route-data/work-order-file.route-data";

@Component({
  selector: 'app-work-order-documents-wizard',
  templateUrl: './work-order-documents-wizard.component.html',
  styleUrls: ['./work-order-documents-wizard.component.scss']
})
export class WorkOrderDocumentsWizardComponent {

  @ViewChild(WorkOrderDocumentsFormComponent) workOrderDocumentsFormComponent: WorkOrderDocumentsFormComponent | null = null;

  constructor(
    private serializerHelperService: CtSerializerHelperService,
    @Inject(MAT_DIALOG_DATA) public data: WorkOrderDocumentsConfiguration | null,
    private dialogRef: MatLegacyDialogRef<any>,
    private _model: CtModelService<any>) {
  }

  ngOnInit() {

    if (!this.data) {

      this.dialogRef.close();
      return;

    }

  }

  submit(parameter: WorkOrderFileCreateParameter) {

    parameter
      .setWorkOrderOid(this.data?.WorkOrder?.Oid);

     const routeData =  WorkOrderFileRouteData().setId(parameter.Oid ?? null);

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(routeData)

    if(parameter.File){

    this._model
      .putInfos(configuration, this.serializerHelperService.objectToFormData(parameter))
      ?.subscribe(
        () => this.dialogRef.close(true),
        (error) => console.error('An error occurred during execution:', error)
      );
    }
    else{

      this._model
      .putInfos(configuration, parameter)
      ?.subscribe(
        () => this.dialogRef.close(true),
        (error) => console.error('An error occurred during execution:', error)
      );

    }

  }

  cancel(){

    this.dialogRef.close(false);

  }

}
